// default vue template
<template>
  <special-gift-card
    :special-card-type="GiftCardTypes.COMPENSATION_CARD"
  />
</template>

<script>

import SpecialGiftCard from "@/views/GiftCard/common/SpecialGiftCard.vue";
import {GiftCardTypes} from "@/views/GiftCard/common/SpecialGiftCard.vue";

export default {
  name: 'CompensationGiftCards',
  components: {SpecialGiftCard},
  data() {
    return {
      GiftCardTypes
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
